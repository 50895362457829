import React, {Component} from 'react';
// import {connect} from 'react-redux';
// import {Counter} from '../components';
// import * as counterActions from '../actions/counter';

class Home extends Component {
  /*shouldComponentUpdate(nextProps, nextState) {
  return nextProps.number !== this.props.number;
}*/

render() {
  return (
    <div style={style.homeContainer}>
    <div style={style.tempContainer}>
    <p style={style.tempText}>
    hi i make music and write lyrics
    </p>
    <a style={style.contactLink} href="https://instagram.com/jidagraphy" target="_blank">instagram</a>
    <a style={style.contactLink} href="https://github.com/jidagraphy" target="_blank">github</a>
    </div>
    <br/>
    <iframe style={style.youtubeEmbed} src="https://www.youtube.com/embed/JT2UL4ZH2Jo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <iframe style={style.youtubeEmbed} src="https://www.youtube.com/embed/EzQsoZYY470" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <iframe style={style.youtubeEmbed} src="https://www.youtube.com/embed/wnNo7DYHKXk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <iframe style={style.youtubeEmbed} src="https://www.youtube.com/embed/u-iZ1LAUG_8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <iframe style={style.youtubeEmbed} src="https://www.youtube.com/embed/MuxAUNusTLg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  );
}
}


const style = {
  homeContainer : {
    textAlign : "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2rem",
    fontFamily: "didot",
    letterSpacing: "5px",
    lineHeight: "3rem",
    padding: "20vh 0"
  },
  tempContainer: {
    padding: "10vh 0",
  },
  tempText : {
    color: "white",
  },
  youtubeEmbed: {
    lineHeight: "2rem",
    margin: "10px 0",
    width: 300,
    height: 300,
  },
  contactLink : {
    color : "rgb(56, 103, 121)",
    fontSize: "1.5rem",
    display: "block",
  }
}

export default Home;
